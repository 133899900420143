import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import CommonParagraph from "../component/Blog/CommonParagraph";
import Heading from "../component/Blog/Heading";
import BlogLink from "../component/Blog/BlogLink";
import { blogList } from "../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/best-sd-neighborhoods-young-professionals"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By {blogData[0].author}
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_The Best Neighborhoods in San Diego for Young Professionals.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                Are you in your early 20s and late 30s, have a steady income, and are considering
                your first home purchase? Then, you might be interested in San Diego neighborhoods
                that score high marks among young professionals.
              </CommonParagraph>
              <CommonParagraph>
                Whether you’re drawn to a buzzing nightlife, walkable streets, a thriving arts
                scene, excellent food, or just want to cut down on your daily commute, here are the
                best neighborhoods for young professionals in San Diego.
              </CommonParagraph>
              <Heading type="h2">North Park</Heading>
              <CommonParagraph>
                Some call it “up & coming,” others say it’s got “hipster vibes.” We just call it
                North Park, a vibrant part of the city known for craft beers, indie boutiques, and
                eclectic restaurants. It’s also one of the{" "}
                <BlogLink
                  url="https://selbysellssd.com/north-park-san-diego-housing-market/"
                  text="safest neighborhoods in San Diego"
                />
                .
              </CommonParagraph>
              <CommonParagraph>
                This district is also home to the Observatory North Park, where you can catch an
                ever-evolving lineup of some of the best genre-bending indie acts. We’re also fans
                of the North Park Thursday Market, which runs every Thursday from 3 to 7 p.m. and is
                perfect for farm-fresh fruits, veggies, cheese, and homemade goodies.
              </CommonParagraph>
              <CommonParagraph>In terms of real estate, the market is competitive.</CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li>The median sale price was $1 million in June, up 34.2% since last year</li>
                <li>54.7% of homes are selling above the list price</li>
                <li>The average home sells about 1% above list price in only 13 days</li>
                <li>Competitive homes typically sell for about 4% above asking price </li>
              </ul>
              <Heading type="h2">Gaslamp Quarter</Heading>
              <CommonParagraph>
                While once a haven of mischief, this former red-light district has become a favorite
                neighborhood for young professionals. Here, you’ll find some of the best restaurants
                in San Diego (most of which have fantastic happy hours), plenty of shopping, and
                clubs. While tourists tend to gravitate towards the area, plenty of local vibes can
                be found here.
              </CommonParagraph>
              <CommonParagraph>
                We’re particularly enthusiastic about the area’s walkability thanks to the Gaslamp
                Promenade, a development project reinventing Fifth Avenue into eight distinctive
                plazas (from L Street to Broadway).
              </CommonParagraph>
              <CommonParagraph>
                We should also mention that from the Gaslamp, you’re only steps away from some of
                the best beaches in the country, including La Jolla Cove, Mission Beach, Sunset
                Cliffs, and more.
              </CommonParagraph>
              <CommonParagraph>
                Are you looking to buy in the Gaslamp? Here’s what you need to know about this
                surprisingly affordable San Diego neighborhood:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li>The median sale price was $525K last month, down 6.3% since last year</li>
                <li>
                  The average home sells 2% below list price and goes pending after 34 days on the
                  market
                </li>
                <li>
                  Competitive homes typically sell at the listing price and go pending in around 17
                  days
                </li>
              </ul>
              <Heading type="h2">Bankers Hill</Heading>
              <CommonParagraph>
                Looking for scenic views of the San Diego Bay and Coronado Island? Or how about a
                post-work stroll through Balboa Park, the 1,200-acre park that's home to stunning
                architecture, museums, gardens, art installations, and more? You'll find all that
                and more in Bankers Hill.
              </CommonParagraph>
              <CommonParagraph>
                Known for its blend of new and old architecture and close proximity to downtown San
                Diego, Bankers Hill continues to top many young professionals' "Best Of" lists. Its
                appealing location near the water, food, entertainment, and all the big-city
                amenities makes it a highly desirable neighborhood.
              </CommonParagraph>
              <CommonParagraph>But how does it stack up in terms of real estate?</CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li>
                  The median sale price was $1 million in June; that’s down 12.9% from last year
                </li>
                <li>
                  The average home sells 2% below list price and goes pending after 38 days on the
                  market
                </li>
                <li>
                  Competitive homes typically sell at the listing price and go pending in around 14
                  days
                </li>
              </ul>
              <Heading type="h2">Mission Valley</Heading>
              <CommonParagraph>
                Mission Valley's central location, which is split by Interstate 8 and runs between
                Interstate 5 and 15, allows you to be anywhere and everywhere in San Diego in
                minutes. That's a big reason we consider it one of San Diego's best neighborhoods
                for young professionals. But that's not all.
              </CommonParagraph>
              <CommonParagraph>
                If you're looking for convenient shopping, you'll find both Fashion Valley Mall on
                Friars Road and Mission Valley Mall on both sides of Mission Center Drive. There are
                also dozens of restaurants, theaters, a golf course, and much more.
              </CommonParagraph>
              <CommonParagraph>
                If you are a young professional looking for one of the best neighborhoods in San
                Diego, Mission Valley may be the fit. Here's what you should know about real estate:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li>
                  The median sale price was $625K last month, up 13.3% since last year. In terms of
                  price, that makes Mission Valley one of the more affordable neighborhoods in San
                  Diego
                </li>
                <li>
                  The average home sells at 36.4% above list price and goes pending after 18 days on
                  the market
                </li>
                <li>Competitive homes typically sell in only 8 days.</li>
              </ul>
              <Heading type="h2">Normal Heights</Heading>
              <CommonParagraph>
                Like laid-back and eclectic vibes? Normal Heights may be just the place you're
                looking to settle down in. There's a lot to love about this
                neighborhood—particularly its walkability score, which gets an 89 out of 100.
              </CommonParagraph>
              <CommonParagraph>
                Setting down roots in Normal Heights means you're only minutes (by foot) from some
                of the best collections of restaurants, coffee shops, and vintage stores in the
                area. Plus, it's home to one of our favorite free music festivals, the Adams Avenue
                Street Fair. In terms of real estate, here's the state of the market in the area:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li>The median sale price was $1 million in June; that's up 12% from last year.</li>
                <li>35.7% of homes sell above asking price and go pending after 30 days.</li>
                <li>
                  Competitive homes typically sell at 2% above the listing price and go pending in
                  around 14 days.
                </li>
              </ul>
              <Heading type="h2">San Diego - Your Way. Let Us Help You Find Your Place!</Heading>
              <CommonParagraph>
                Are you tired of reading about all the fantastic things San Diego has to offer and
                ready to put down some roots? You're in the right place. Whether you're drawn to the
                vibrant downtown lifestyle in the heart of the Gaslamp Quarter, the breweries and
                endless culinary choices of North Park, or anything in between, our experienced team
                is here to help you find your dream home. <ContactSlideoutLink text="Contact us" />{" "}
                today and start your San Diego adventure!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
